import React from "react";
import { Maybe, Query, SanityCategory } from "@graphql-types";
import { Container, P, H2 } from "@util/standard";
import { isBrowser, pageWidthMargin } from "@util/helper";
import { useStore } from "@state/store";
import { StyledMethodContainer } from "@shared/shared.styles";
import ProductInfo from "@components/products/productInfo";
import CategoryPreview from "@shared/categoryPreview";
import FeaturedRecipe from "@shared/featuredRecipe";
import RelatedCollections from "@components/products/relatedCollections";
import ImageTextBlockWithCarousel from "@shared/imageTextBlock";
import GiftsCarousel from "@components/products/giftsCarousel";
import SEO from "@shared/seo";
import Layout from "@shared/layout";
import { BreadcrumbPageContext } from "@util/types";
import { slug } from "@util/constants";
import { graphql, PageProps } from "gatsby";

interface Props extends PageProps {
  data: Query;
  pageContext: BreadcrumbPageContext;
}

const ProductTemplate = (props: Props) => {
  const queryString = isBrowser() && window.location.search;
  const urlParams = queryString && new URLSearchParams(queryString);

  const bundleId = urlParams ? urlParams.get("bundle") : undefined;

  const product =
    props.pageContext.iban == "nz"
      ? props.data.allSanityProduct.nodes[0]
      : props.data.allSanityProductAu.nodes[0];

  const homeQuery = props.data.allSanityHomePage.nodes[0];
  const landingData = props.data.allSanityGiftingLandingPage.nodes[0];
  const { categoryParams } = useStore();
  const margin = pageWidthMargin();

  if (!product) {
    return <P color="errorRed">Error loading Page</P>;
  }

  const isGift = product.content?.shopify?.isGift ?? false;

  const giftData = isGift
    ? landingData.corporateGifting
    : homeQuery?.gifting?.giftingBlock;

  const currentCategory = (): SanityCategory | undefined => {
    let obj = undefined;
    if (
      categoryParams.hasCategory ||
      props.data.allSanityCategory.nodes.length > 0
    ) {
      obj = props.data.allSanityCategory.nodes[0];
    }

    return obj;
  };

  const relatedCollections = (): Maybe<SanityCategory>[] => {
    if (categoryParams.hasCategory) {
      const currentCollection = props.data.allSanityCollection.nodes[0];

      let categoryLength = 1;
      if (
        currentCollection?.main?.categories &&
        currentCollection.main.categories.length > 0
      ) {
        return currentCollection.main?.categories.filter(category => {
          if (
            category?.main?.slug?.current !== categoryParams.categoryHandle &&
            categoryLength < 4
          ) {
            categoryLength++;
            return true;
          }
          return false;
        });
      }
    }
    return [];
  };

  const defaultMetaTitle = "Shop | " + product.content?.main?.title;
  const defaultMetaDescription = product.content?.main?.productDescription;
  const defaultMetaImage = product.content?.main?.mainImage?.asset?.url;

  let parentCategoryCrumb;
  if (isGift) {
    parentCategoryCrumb = {
      crumbLabel: "Gifting",
      pathname: `/${slug.gifting}`,
    };
  } else if (categoryParams) {
    parentCategoryCrumb = {
      crumbLabel: categoryParams.categoryName,
      pathname: `/${slug.shopAll}/${categoryParams.categoryHandle}`,
    };
  }

  return (
    <>
      <SEO
        seoData={product.content?.meta}
        slug={`shop/${product.content?.main?.slug?.current}`}
        overwriteTitle={
          product.content?.meta?.pageTitle
            ? product.content?.meta?.pageTitle
            : defaultMetaTitle
        }
        overwriteDescription={
          product.content?.meta?.pageDescription
            ? product.content?.meta?.pageDescription
            : defaultMetaDescription
        }
        overwriteImage={
          product.content?.meta?.ogImage?.asset?.url
            ? product.content?.meta?.ogImage?.asset?.url
            : defaultMetaImage
        }
      />
      <Layout>
        <Container flexDirection="column">
          <ProductInfo
            product={product}
            isGift={isGift}
            parentCrumb={parentCategoryCrumb}
            pageContext={props.pageContext}
            bundleId={bundleId}
            categoryColour={
              product.content?.main?.backgroundColour?.value ??
              currentCategory()?.categoryColour?.value
            }
          />

          {/* Other you may like (Not for gift) */}
          {currentCategory() && (
            <Container
              position="relative"
              width={`calc(100% - ${margin} - ${margin})`}
              margin="120px auto"
              tabletMargin="20px auto 60px"
            >
              <CategoryPreview
                sanityCategory={currentCategory()}
                excludeProduct={product}
                slug="shop-all"
                width={`calc(100% + ${margin})`}
                margin={`0 -${margin} 0 0`}
                tabletMargin={`0 -${margin} 0 0`}
                showTabletNav={true}
                title={`Other ${currentCategory()?.main?.title}`}
                iban={props.pageContext.iban}
              />
            </Container>
          )}

          {isGift &&
            Boolean(product.content?.info?.relatedProducts?.length) && (
              <Container
                position="relative"
                width={`calc(100% - ${margin} - ${margin})`}
                margin="120px auto"
                tabletMargin="20px auto 60px"
              >
                <CategoryPreview
                  products={product?.content?.info?.relatedProducts}
                  width={`calc(100% + ${margin})`}
                  margin={`0 -${margin} 0 0`}
                  tabletMargin={`0 -${margin} 0 0`}
                  showTabletNav={true}
                  title="This gift includes"
                />
              </Container>
            )}

          {/* Other Gifts for Gifts */}
          {isGift && <GiftsCarousel />}

          {/* Featured Recipe */}
          {props.data.allSanityRecipe.nodes &&
            props.data.allSanityRecipe.nodes.length > 0 && (
              <FeaturedRecipe recipes={props.data.allSanityRecipe.nodes} />
            )}

          {/* Blog (Not for gift) */}

          {/* Related Collection (Not for gift) */}
          {!isGift && relatedCollections().length > 0 && (
            <StyledMethodContainer bacgkroundUrl>
              <Container
                width={`calc(100% - ${margin} - ${margin})`}
                margin="120px auto"
                tabletMargin="63px auto 84px"
                flexDirection="column"
              >
                <Container>
                  <H2 margin="0 0 .7em">Related Categories</H2>
                </Container>
                <RelatedCollections categories={relatedCollections()} />
              </Container>
            </StyledMethodContainer>
          )}

          {/* Newsletter for Gifts */}
          {/* {isGift && <NewsletterCTA />} */}

          {/* Gifting */}
          {/* Same component as the one on the homepage */}
          {giftData && (
            <ImageTextBlockWithCarousel
              singleBlockData={giftData}
              customHeight="650px"
              customMobileHeight="720px"
            />
          )}

          {/* You may also like (Not for gift) */}
          {!isGift &&
            product.content?.info?.relatedProducts &&
            product.content.info.relatedProducts?.length > 0 && (
              <Container
                position="relative"
                width={`calc(100% - ${margin} - ${margin})`}
                margin="120px auto"
                tabletMargin="20px auto 60px"
              >
                <CategoryPreview
                  products={product?.content?.info?.relatedProducts}
                  width={`calc(100% + ${margin})`}
                  margin={`0 -${margin} 0 0`}
                  tabletMargin={`0 -${margin} 0 0`}
                  showTabletNav={true}
                  title="You may also like"
                />
              </Container>
            )}

          {/* Newsletter not Gift (Not for gift) */}
          {/* {!isGift && <NewsletterCTA />} */}

          {/* Review */}
        </Container>
      </Layout>
    </>
  );
};

export default ProductTemplate;

export const query = graphql`
  query ProductInnerQueryNew(
    $slug: String
    $iban: String
    $shouldFetchAU: Boolean!
    $shouldFetchNZ: Boolean!
  ) {
    allSanityProduct(
      filter: { content: { main: { slug: { current: { eq: $slug } } } } }
    ) @include(if: $shouldFetchNZ) {
      nodes {
        ...sanityProductInfo
      }
    }
    allSanityProductAu(
      filter: { content: { main: { slug: { current: { eq: $slug } } } } }
    ) @include(if: $shouldFetchAU) {
      nodes {
        ...sanityProductInfoAu
      }
    }
    allSanityCategory(
      filter: {
        products: {
          elemMatch: { content: { main: { slug: { current: { eq: $slug } } } } }
        }
      }
    ) {
      nodes {
        categoryColour {
          value
        }
        products {
          _id
          content {
            main {
              title
              backgroundColour {
                value
              }
              slug {
                current
              }
              featuredBadge {
                ...sanityAllergenOrProductBadge
              }
              featuredAllergen {
                ...sanityAllergenOrProductBadge
              }
              mainImage {
                asset {
                  gatsbyImageData(
                    width: 260
                    placeholder: BLURRED
                    formats: AUTO
                  )
                }
              }
            }
            shopify {
              defaultPrice
              defaultCompareAtPrice
              isSoldOut
              defaultVariant {
                variantId
              }
            }
            info {
              declaredWeight
            }
          }
        }
        main {
          title
          slug {
            current
          }
        }
        productsAu {
          _id
          content {
            main {
              title
              slug {
                current
              }
              featuredBadge {
                ...sanityAllergenOrProductBadge
              }
              featuredAllergen {
                ...sanityAllergenOrProductBadge
              }
              mainImage {
                asset {
                  gatsbyImageData(
                    width: 260
                    placeholder: BLURRED
                    formats: AUTO
                  )
                }
              }
            }
            shopify {
              defaultPrice
              defaultCompareAtPrice
              isSoldOut
              defaultVariant {
                variantId
              }
            }
            info {
              declaredWeight
            }
          }
        }
      }
    }
    allSanityCollection(
      filter: {
        main: {
          categories: {
            elemMatch: {
              products: {
                elemMatch: {
                  content: { main: { slug: { current: { eq: $slug } } } }
                }
              }
            }
          }
          region: { iban: { eq: $iban } }
        }
      }
    ) {
      nodes {
        _id
        ...sanityCollectionPreviewReduced
      }
    }
    allSanityRecipe(
      filter: {
        related: {
          relatedProductsNZ: {
            elemMatch: {
              content: { main: { slug: { current: { eq: $slug } } } }
            }
          }
        }
      }
    ) {
      nodes {
        ...sanityFeaturedRecipe
      }
    }
    allSanityHomePage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        gifting {
          ...sanityGiftingLanding
        }
      }
    }
    allSanityGiftingLandingPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        ...sanityGiftingLanding
      }
    }
  }
`;
